import { css } from '@emotion/css'

export const Footer = () => {
  return (
    <footer className={footerStyle}>
      <h2 className={contactStyle}>contact@tightgrips.band</h2>
    </footer>
  )
}

const footerStyle = css`
  margin: 1rem;
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
`

const contactStyle = css`
  font-size: 16px;
`
