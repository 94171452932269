import { css } from '@emotion/css'

export const AboutDrawerContent = () => {
  return (
    <div className={containerStyle}>
      <img src="/images/close-up.jpg" className={imageStyle} />
      <h3 className={headingStyle}>About</h3>
      <p className={textStyle}>
        Tight Grips is a power trio that came to be in the summer of 2014. So
        far, the band has released two albums: “Storyline” and “Move Along” and
        they have recently announced their third release titled “Endless Gifts
        for me, myself and I”.
      </p>
      <p className={textStyle}>
        Thanks to the mentioned releases, the band managed to play several major
        festivals - Tam Tam, Ferragosto Jam, Hoomstock, Rocklive, Bedem Fest,
        Tesla Radio Festival and Pickup Music Festival to name a few. Alongside
        those festivals, the band has also embarked on a mini tour in Great
        Britain and in the more recent time they have shared the stage with the
        almighty Villagers of Ioannina City.
      </p>
      <p className={textStyle}>
        From one release to the other they have changed their approach to music
        and with that said the upcoming album is by far the heaviest and the
        most progressive - inspired by the grunge movement of the nineties as
        well as with more recent stoner rock and prog rock releases.
      </p>
      <div className={emptyStyle} />
    </div>
  )
}

const containerStyle = css`
  padding: 2rem;
  overflow: scroll;
  height: 100%;
  background-color: #f4f4f4;
`

const imageStyle = css`
  width: 100%;
  margin-bottom: 1.5rem;
`

const headingStyle = css`
  color: #0d0d0d;
  margin-bottom: 1.5rem;
`

const textStyle = css`
  color: #0d0d0d;
  font-size: 16px;
`

const emptyStyle = css`
  height: 4rem;
`
