import { css } from '@emotion/css'
import 'react-modern-drawer/dist/index.css'
import { HomePage } from './home/HomePage'

function App() {
  return (
    <main className={containerStyle}>
      <HomePage />
    </main>
  )
}

const containerStyle = css`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
`

export default App
