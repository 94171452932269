import { css } from '@emotion/css'

type NavigationBarProps = {
  handleAboutClick(): void
  handleEventsClick(): void
}

export const NavigationBar = (props: NavigationBarProps) => {
  return (
    <header className={navStyle}>
      <h1 className={logoStyle}>Tight Grips</h1>
      <div>
        <a onClick={props.handleAboutClick}>about</a>
        {/* <a onClick={props.handleEventsClick}>events</a> */}
      </div>
    </header>
  )
}

const navStyle = css`
  margin: 1rem;
  z-index: 2;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const logoStyle = css`
  font-size: px;
`
