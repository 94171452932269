import { css } from '@emotion/css'
import { NavigationBar } from '../shared/nav/NavigationBar'
import { Footer } from '../shared/footer/Footer'
import { useState } from 'react'
import Drawer from 'react-modern-drawer'
import useScreenSize from '../utils'
import { AboutDrawerContent } from './drawers/about/AboutDrawerContent'

export const HomePage = () => {
  const screenSize = useScreenSize()

  const [isAboutOpen, setIsAboutOpen] = useState(false)
  // const [isEventsOpen, setIsEventsOpen] = useState(false)

  const toggleAbout = () => {
    setIsAboutOpen((prevState) => !prevState)
  }

  // const toggleEvents = () => {
  //   setIsEventsOpen((prevState) => !prevState)
  // }

  return (
    <>
      <section className={sectionStyle}>
        <NavigationBar
          handleAboutClick={toggleAbout}
          handleEventsClick={toggleAbout}
        />
        <div className={overlayStyle} />
        <Footer />
      </section>
      <Drawer
        size={screenSize.width > 820 ? '50vw' : '90%'}
        direction={screenSize.width > 820 ? 'right' : 'bottom'}
        open={isAboutOpen}
        onClose={toggleAbout}
        lockBackgroundScroll
      >
        <AboutDrawerContent />
      </Drawer>
      {/* <Drawer
        size={screenSize.width > 820 ? '50vw' : '90%'}
        direction={screenSize.width > 820 ? 'right' : 'bottom'}
        open={isEventsOpen}
        onClose={toggleEvents}
        lockBackgroundScroll
      >
        <div>Hello World</div>
      </Drawer> */}
    </>
  )
}

const sectionStyle = css`
  background-image: url('images/hero-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const colors = ['#001F14', '#030011', '#1F1300', '#000000']

const overlayStyle = css`
  background-color: ${colors[Math.floor(Math.random() * colors.length)]};
  opacity: 0.7;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
`
